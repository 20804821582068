import { type SearchConsole } from 'types/search_console';
import { postRequest } from 'utils/rest-api-util';
import { StringUtil } from 'utils/string-util';

export const postSearchConsole = async (
  analyticId: string,
  searchConsoleParams: SearchConsole,
): Promise<SearchConsole> => {
  const requestPath = `/api/v1/analytics/${analyticId}/search_console`;

  const response = await postRequest(requestPath, searchConsoleParams);

  return StringUtil.snakeObjectToCamelObject(
    await response.json(),
  ) as SearchConsole;
};
